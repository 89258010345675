<template>
  <div class="detail-page">
    <Head title="一元拍西文书（每周上新）">
      <img
        @click.stop="onLike(1)"
        v-show="isLike == 0"
        class="like"
        src="../../assets/images/top_btn_collection.png"
        alt
      />
      <img
        @click.stop="onLike(0)"
        v-show="isLike != 0"
        class="like"
        src="../../assets/images/top_btn_collection_sel.png"
        alt
      />
      <span class="like-text">收藏</span>
    </Head>
    <Warpper
      top="40"
      :bottom="type == 0 ? '101' : '60'"
      ref="warpper"
      style="overflow: auto"
    >
      <div class="banner-box">
        <swiper ref="swiper" :options="swiperOptions">
          <swiper-slide
            class="swiper-slide"
            :key="banner"
            v-for="banner in banners"
          >
            <img :src="banner" preview="1" />
          </swiper-slide>
        </swiper>
        <div class="banner-bar">{{ activeIndex + 1 }}/{{ banners.length }}</div>
      </div>

      <div class="goods-head-info" v-if="type == 0">
        <div class="goods-head-box1">
          <label>当前价</label>
          <div>
            <span>¥{{ product && product.now_price }}</span>
            <span>({{ product && product.touser_nickname }})</span>
          </div>
        </div>
        <div class="goods-head-box2">
          <label>距离结束</label>
          <div>
            <span class="yellow" v-if="product && product.state == 0">{{
              count_txt
            }}<span class="yellow" v-show="lateState==1">(延迟)</span></span>
            <span v-if="product && product.state == 1" class="yellow"
              >拍卖结束</span
            >
          </div>
        </div>
      </div>

      <!-- 拍卖 -->
      <!-- <div class="goods-status goods-status-a" v-if="type==0 && product && product.state==0">
        <p>
          <img src="../../assets/images/icon_clock_white.png" alt /> 正在进行
        </p>
        <span>距离结束</span>
        <span class="yellow">{{count_txt}}</span>
      </div>
      <div class="goods-status goods-status-b" v-if="type==0 && product && product.state==1">
        <span>距离结束</span>
        <span class="yellow">拍卖结束</span>
      </div> -->
      <!-- 拍卖 -->

      <h5 class="goods-title">{{ product && product.title }}</h5>

      <!-- <h6 class="goods-price">
        <label>{{type==0?'当前价':'一口价'}}</label>
        <span class="red">¥{{product&&product.now_price}}</span>
        <span v-if="type==0">({{product&&product.touser_nickname}})</span>
      </h6> -->

      <h6 class="goods-price" v-if="type == 1">
        <label>一口价</label>
        <span class="red">¥{{ product && product.now_price }}</span>
      </h6>

      <div class="goods-info">
        <ul>
          <li v-if="type == 0">
            <label>{{ type == 0 ? "起拍价" : "一口价" }}</label>
            <span>¥{{ product && product.start_price }}</span>
          </li>
          <li v-if="type == 0">
            <label>加价幅度</label>
            <span>¥{{ product && product.add_price }}</span>
          </li>
          <li v-if="type == 0">
            <label>延时周期</label>
            <span>{{ product && product.late_time }}秒/次</span>
          </li>
          <li>
            <label>浏览量</label>
            <span>{{ product && product.hot }}次</span>
          </li>
          <li>
            <label>快递费用</label>
            <span>¥{{ product && product.expenses }}（多拍合并运费）</span>
          </li>
          <li v-if="type == 0">
            <label>结束时间</label>
            <span>{{ product && product.end_time }}</span>
          </li>
        </ul>
      </div>

      <div class="goods-browse">
        <!-- <h5>
          <img src="../../assets/images/icon_browse.png" alt />
          <p>
            浏览
            <span>{{product&&product.hot}}</span>人次
          </p>
        </h5>-->
        <h5 class="new-title">浏览人数({{ product && product.hot }})</h5>
        <ul>
          <li v-for="(item, index) in lookList" :key="index">
            <img v-lazy="item.pic" />
          </li>
        </ul>
      </div>

      <div class="goods-box">
        <h5 class="new-title">基本信息</h5>
        <div class="goods-box-a">
          <p>作者：{{ (product && product.writer) || "暂无资料" }}</p>
          <p>出版社：{{ (product && product.company) || "暂无资料" }}</p>
          <p>出版时间：{{ (product && product.printtime) || "暂无资料" }}</p>
          <p>重量：{{ (product && product.bookweight) || "暂无资料" }}</p>
          <p>尺寸：{{ (product && product.booksize) || "暂无资料" }}</p>
          <p>页数：{{ (product && product.pagenum) || "暂无资料" }}</p>
          <p></p>
        </div>

        <h5 class="new-title">商品详情</h5>
        <div class="goods-box-a" v-html="product && product.content"></div>

        <h5 v-if="type == 0" class="new-title">参拍须知</h5>
        <ul v-if="type == 0" class="goods-ps">
          <li>
            <label>出价须知</label>
            <p>拍卖非游戏，请理性出价。</p>
          </li>
          <li>
            <label>关于运费</label>
            <p>竞拍成功后，请于7日内付款，拍得多件快递费只收1件运费。</p>
          </li>
          <li>
            <label>违约责任</label>
            <p>如未及时付款，视为违约，需扣除拍品价格的15%作为违约金。</p>
          </li>
          <li>
            <label>关于退货</label>
            <p>
              拍品如无描述不符问题，谢绝退货。因拍卖规则特殊，不接受无理由退货。
            </p>
          </li>
        </ul>

        <h5 v-if="offerList.length > 0 && type == 0">
          出价记录（{{ offerTotal }}）
          <router-link :to="'/detail/record?id=' + id">
            查看全部
            <i></i>
          </router-link>
        </h5>
        <ul v-if="offerList.length > 0 && type == 0" class="offer-list">
          <li
            v-for="(item, index) in offerList"
            :key="index"
            :class="{ active: item.buyer }"
          >
            <div>
              <img :src="item.avatar" alt />
              <div>
                <h5>{{ item.nickname }}</h5>
                <p>{{ item.date }}</p>
              </div>
            </div>
            <i></i>
            <h6>
              ¥{{ item.price }}
              <span class="weituo-tag" v-if="item.weituo > 0">(委托)</span>
            </h6>
          </li>
        </ul>

        <h5
          v-if="goodsList.length > 0 && type != -1"
          style="margin-bottom: 0.2rem"
        >
          更多{{ ["拍品", "一口价"][type] }}
          <router-link to="/">
            返回首页
            <i></i>
          </router-link>
        </h5>

        <div class="goods-list-box" v-if="goodsList.length > 0 && type != -1">
          <div
            v-for="(item, index) in goodsList"
            :key="index"
            @click="toDetail(item.id)"
          >
            <GoodsItem
              :type="type"
              :pic="item.pic"
              :title="item.title"
              :count="item.count"
              :buyer="item.buyer"
              :price="item.price"
              :date="item.date"
              :late="item.late_state"
              style="padding: 0.2rem 0rem 0"
            />
          </div>
        </div>
      </div>
    </Warpper>

    <!-- 拍卖 -->
    <div class="trust" v-if="type == 0">
      <button :class="{ active: trustIndex == 0 }" @click.stop="trustIndex = 0">
        立即出价
      </button>
      <button :class="{ active: trustIndex == 1 }" @click.stop="trustIndex = 1">
        委托出价
      </button>
    </div>
    <div class="foot" v-if="type == 0">
      <div class="fns">
        <h6 class="end" :class="{ active: remind != 0 }" @click.stop="onRemind">
          <i></i>
          <p>
            结束
            <br />提醒
          </p>
        </h6>
        <div>
          <i @click.stop="onSub" class="sub"></i>
          <p v-if="trustIndex == 0">{{ price }}</p>
          <p v-else>
            <input
              @blur="blurPriceWeituo"
              style="
                border: 0;
                width: 100%;
                height: 100%;
                outline: none;
                text-algin: center;
                text-align: center;
              "
              type="number"
              v-model="priceWeituo"
            />
          </p>
          <i @click.stop="onAdd" class="add"></i>
        </div>
      </div>
      <button @click.stop="onBuy" v-if="product && product.state == 0">
        <img src="../../assets/images/home_icon_auction.png" alt />
        {{ ["立即出价", "委托出价"][trustIndex] }}
      </button>
      <button class="end-btn" v-else>已结束</button>
    </div>
    <!-- 拍卖 -->

    <!-- 一口价 -->
    <div class="foot" v-if="type == 1">
      <h5>
        <label>一口价</label>
        <span>¥{{ product && product.now_price }}</span>
        <span class="kucun-text" v-if="product">库存{{ product.num }}</span>
      </h5>
      <button @click="buyPopup = true" v-if="product && product.state == 0">
        立即购买
      </button>
      <button class="end-btn" v-else>已结束</button>
    </div>
    <!-- 一口价 -->

    <div class="page-mask" v-if="!loaded"></div>

    <div @click.stop="toCart" class="cart-btn" v-if="type == 1">
      <div>{{ cartCount }}</div>
    </div>

    <!-- 领先与淘汰语音 -->
    <!-- <audio src="../../assets/audio/chuju.mp3" ref="chuju"></audio> -->
    <!-- <audio src="../../assets/audio/lingxian.mp3" ref="lingxian"></audio> -->

    <audio :src="BASEURL+'/upload/chuju.mp3'" ref="chuju"></audio>
    <audio :src="BASEURL+'/upload/lingxian.mp3'" ref="lingxian"></audio>
    <!-- 领先与淘汰语音 -->

    <!-- 一口价弹层 -->
    <div class="cart-surper-mask" v-show="buyPopup"></div>
    <div class="cart-surper-box" v-show="buyPopup" v-if="product">
      <i @click="buyPopup = false"></i>
      <div class="cart-surper-info">
        <img :src="product.pic" />
        <div>
          <h5>{{ product.title }}</h5>
          <p>
            现价：
            <span>¥{{ product.now_price }}</span>
          </p>
        </div>
      </div>
      <div class="cart-num-fns">
        <span>数量</span>
        <p>
          <i class="cart-surper-sub" @click.stop="buyNumSub"></i>
          <span class="cart-surper-num">{{ buyNum }}</span>
          <i class="cart-surper-add" @click.stop="buyNumAdd"></i>
        </p>
      </div>
      <div class="cart-surper-btns">
        <div @click="addCart">加入购物车</div>
        <div @click="onBuy">立即结算</div>
      </div>
    </div>
    <!-- 一口价弹层 -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import GoodsItem from "components/GoodsItem";
import MyMqtt from "@/plugins/mqtt";
import { BASEURL } from "@/config";

import {
  reqProInfo,
  reqSetLike,
  reqLookList,
  reqBuyList,
  reqMoreList,
  reqImmedPrice,
  reqSetNotice,
  reqSetWeituo,
  reqGetCartInfo,
  reqAddCart,
} from "@/network/api";

export default {
  name: "detail",
  data() {
    return {
      BASEURL,
      userInfo: this.$store.getters.userInfo,
      id: "",
      buyPopup: false,
      buyNum: 1,
      trustIndex: 0,
      remind: 0,
      num: 1,
      min: 1,
      unit: 10,
      price: 0,
      priceWeituo: 0,
      type: -1,
      isLike: 0,
      cartCount: 0,
      activeIndex: 0,
      offerTotal: 0,
      banners: [],
      offerList: [],
      goodsList: [],
      lookList: [],
      count: "",
      product: null,
      loaded: false,
      lateState: 0,
      swiperOptions: {
        on: {
          transitionEnd: (e) => {
            this.activeIndex = e.activeIndex;
          },
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    GoodsItem,
  },
  directives: {
    swiper: directive,
  },
  watch: {
    loaded(v) {
      if (v) setTimeout(() => this.$Indicator.close());
    },
    trustIndex(v) {
      if (v == 0 && this.price < this.product.now_price) {
        this.price = this.product.now_price;
      }
    },
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
    count_txt() {
      if (this.count == "" || this.count < 0) return "检测延时";
      return this.changeDate(this.count);
    },
  },
  created() {
    this.$Indicator.open();
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    // console.log(this.id, this.type);
    if (!this.id) this.$router.back();
    if (this.type == 0) {
      this.mqtt = new MyMqtt();
      this.mqtt.init();
      this.mqtt.message = this.onMessage;
    }
    this.getData();
    if (this.type == 1) {
      this.getCartInfo();
    }
  },
  mounted(){
      // setInterval(()=>{
      //   this.$refs.lingxian.play();
      // },3000)
  },
  methods: {
    toEarnest(index) {
      // 跳转保证金
      this.$router.push({ path: "/earnest",query:{ state:index || 0} });
    },
    blurPriceWeituo() {
      this.priceWeituo = +this.priceWeituo;
      if (this.priceWeituo < 0) this.priceWeituo = 0;
    },
    getCartInfo() {
      reqGetCartInfo({
        user_id: this.userInfo.id,
      }).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return;
        this.cartCount = backdata.cart_count;
      });
    },
    toCart() {
      this.$router.push({
        name: "cart",
      });
    },
    onMessage(msg) {
      this.$store.dispatch("other/pushMqttList", msg);
      if (this.type == 1) return;
      if (msg.text.id == this.id) {
        this.updateData(msg);
      } else {
        this.goodsList = [].map.call(this.goodsList, (item) => {
          if (msg.text.id == item.id) {
            item = this.changeItem(msg, item);
          }
          return item;
        });
      }
    },
    changeItem(msg, item) {
      let obj = item;
      const { action, text } = msg;
      if (action == "edit" || action == "expire") {
        for (const key in text) {
          if (text.hasOwnProperty(key)) {
            obj[key] = msg.text[key];
          }
        }
        this.countDown();
      }
      if (action == "state") {
        setTimeout(() => {
          this.onDelItem(item.id);
        }, 5000);
      }
      return obj;
    },
    onDelItem(id) {
      if (this.type == 1) return;
      this.goodsList = [].filter.call(this.goodsList, (item) => {
        return item.id != id;
      });
    },
    buyNumAdd() {
      this.buyNum += 1;
      if (this.buyNum > this.product.num) {
        this.$toast({ message: "库存为" + this.product.num });
        this.buyNum = this.product.num;
      }
    },
    buyNumSub() {
      this.buyNum -= 1;
      if (this.buyNum < 1) {
        this.buyNum = 1;
      }
    },
    addCart() {
      reqAddCart({
        id: this.id,
        num: this.buyNum,
      }).then((res) => {
        const { code, msg, backdata } = res;
        this.$toast({ message: msg });
        if (code != 0) return;
        this.getCartInfo();
      });
    },
    onBuy() {
      if (this.product.state == 1) {
        return this.$toast({ message: "已结束" });
      }
      if (!this.$store.getters.userInfo.id) {
        return this.$router.push({
          name: "login",
        });
      }
      if (this.type == 1) {
        reqImmedPrice({
          id: this.id,
          price: this.product.now_price,
          num: this.buyNum,
        }).then((res) => {
          const { code, msg } = res;
          this.$toast({ message: msg });
          if(msg=="保证金不足") return this.toEarnest();
          if (code != 0) return;
          this.$router.push({
            name: "order",
            query: {
              index: 1,
            },
          });
        });
        return;
      }
      // trustIndex 0 立即 1 委托
      if (this.trustIndex == 0) {
        if (
          +this.price <= +this.product.now_price &&
          +this.product.touser != 0
        ) {
          this.onAdd();
        }
        reqImmedPrice({
          id: this.id,
          price: this.trustIndex == 0 ? this.price : this.priceWeituo,
        }).then((res) => {
          const { code, msg } = res;
          this.$toast({ message: msg });
          if(msg=="保证金不足") return this.toEarnest();
          if (code != 0) return;
        });
      }

      if (this.trustIndex == 1) {
        reqSetWeituo({
          id: this.id,
          price: this.priceWeituo,
        }).then((res) => {
          const { code, msg } = res;
          this.$toast({ message: msg });
        });
      }
    },
    countDown() {
      if (this.timer) clearInterval(this.timer);
      if (this.type == 1) return;
      if (this.product.state == 1) return (this.count = 0);
      this.timer = setInterval(() => {
        this.count = new Date(this.product.end_time.replace(/-/g, "/")) - new Date().getTime();
        if (this.count <= 0) {
          this.count = 0;
          // clearInterval(this.timer);
          // this.getData();
        }
      }, 1000);
    },
    listDown() {
      if (this.list_timer) clearInterval(this.list_timer);
      if (this.type == 1) return;
      this.list_timer = setInterval(() => {
        this.goodsList = [].map.call(this.goodsList, (item) => {
          let _c = new Date(item.end_time.replace(/-/g, "/")).getTime() - new Date().getTime();
          item.count = _c <= 0 ? "" : _c.toString();
          return item;
        });
      }, 1000);
    },
    onRemind() {
      // 结束提醒
      if (!this.remind) {
        this.$MessageBox
          .alert(
            `<img style="width:160px;height:160px" src="${require("../../assets/images/qrcode.jpg")}" />`,
            "关注公众号，接收结束提醒"
          )
          .then((action) => {});
      }
      reqSetNotice({
        id: this.id,
      }).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.remind = backdata.notice;
      });
    },
    getData() {
      reqProInfo({
        id: this.id,
      }).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.loaded = true;
        this.product = backdata.product;
        // console.log(this.product);
        this.isLike = this.product.like;
        this.remind = +this.product.notice;
        this.unit = Number(this.product.add_price);
        this.lateState = +this.product.late_state;
        if (+this.product.touser) {
          this.price = Number(this.product.now_price) + this.unit;
        } else {
          this.price = Number(this.product.now_price);
        }
        this.priceWeituo = this.price;
        // if (+this.product.weituo > 0) {
        //   // this.trustIndex = 1;
        //   this.priceWeituo = +this.product.weituo;
        // } else {
        //   this.priceWeituo = this.price;
        // }
        this.banners = this.product.pic_list.map((item) => item.pic);
        this.type = this.product.model;
        this.count = new Date(this.product.end_time.replace(/-/g, "/")) - new Date().getTime();
        this.getLookList();
        this.getBuyList();
        this.getMoreList();
        if (this.product.state == 0) {
          this.countDown();
        }
      });
    },
    updateData(msg) {
      if (msg.action == "edit") {
        // 修改出价记录
        // headpic touser_nickname touser now_price times
        // offerList
        this.offerTotal = Number(this.offerTotal)+1;
        const { headpic, touser_nickname, touser,  now_price, times,  weituo } = msg.text;
        this.offerList.forEach((item) => {
          item.buyer = 0;
        });
        this.offerList.unshift({
          uid: touser,
          price: now_price,
          avatar: headpic,
          date: times,
          nickname: touser_nickname,
          buyer: 1,
          weituo: weituo || 0,
        });
        if (this.offerList.length > 5) {
          this.offerList.length = 5;
        }

        // this.offerList = backdata.list.map(item => {
        //   item.avatar = item.headpic;
        //   item.nickname = item.touser_nickname;
        //   item.buyer = item.winer;
        //   item.date = item.times;
        //   return item;
        // });
      }
      if (this.userInfo.id) {
        if (msg.action == "edit") {
          if (msg.text.touser == this.userInfo.id) {
            this.$refs.lingxian.play();
          }
          if (
            this.product.touser != msg.text.touser &&
            this.product.touser == this.userInfo.id
          ) {
            this.$refs.chuju.play();
          }
        }
      }
      for (const key in msg.text) {
        this.product[key] = msg.text[key];
      }
      this.lateState = +this.product.late_state;
      this.count = new Date(this.product.end_time.replace(/-/g, "/")) - new Date().getTime();
      if (this.price <= this.product.now_price && this.price != 0) {
        this.price = Number(this.product.now_price) + this.unit;
        this.priceWeituo = this.price;
      }
      
      // reqProInfo(
      //   {
      //     id: this.id
      //   },
      //   false
      // ).then(res => {
      //   const { code, msg, backdata } = res;
      //   if (code != 0) return this.$toast({ message: msg });
      //   this.loaded = true;
      //   this.product = backdata.product;
      //   console.log(this.product);
      //   this.isLike = this.product.like;
      //   this.remind = +this.product.notice;
      //   this.price = Number(this.product.now_price);
      //   this.unit = Number(this.product.add_price);
      //   this.banners = this.product.pic_list.map(item => item.pic);
      //   this.type = this.product.model;
      //   this.count = new Date(this.product.end_time) - new Date().getTime();
      //   this.getBuyList();
      //   if (this.product.state == 0) {
      //     this.countDown();
      //   }
      // });
    },
    getLookList() {
      reqLookList(
        {
          id: this.id,
        },
        false
      ).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.lookList = backdata.viewlist;
      });
    },
    getBuyList() {
      reqBuyList(
        {
          id: this.id,
          page: 1,
          limit: 5,
        },
        false
      ).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.offerTotal = backdata.total;
        this.offerList = backdata.list.map((item) => {
          item.avatar = item.headpic;
          item.nickname = item.touser_nickname;
          item.buyer = item.winer;
          item.date = item.times;
          return item;
        });
      });
    },
    getMoreList() {
      reqMoreList(
        {
          id: this.id,
        },
        false
      ).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.goodsList = res.backdata.map((item) => {
          item.count = new Date(item.end_time.replace(/-/g, "/")) - new Date().getTime();
          item.price = item.now_price;
          item.buyer = item.touser_nickname;
          item.date = item.end_time;
          return item;
        });
        this.listDown();
      });
    },
    toDetail(id) {
      // 跳转详情
      this.id = id;
      this.getData();
      this.$refs.warpper.$el.scrollTop = 0;
      this.$router.replace({
        name: "detail",
        query: {
          type: this.type,
          id,
        },
      });
      // window.location.reload();
    },
    onLike(n) {
      // 收藏
      reqSetLike({
        id: this.id,
      }).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.isLike = backdata.like;
      });
    },
    onAdd() {
      if (this.trustIndex == 0) {
        this.price = Number(this.price) + Number(this.unit);
      } else {
        if (this.priceWeituo < this.product.now_price) {
          if (+this.product.touser) {
            this.priceWeituo =
              Number(this.product.now_price) + Number(this.unit);
          } else {
            this.priceWeituo = Number(this.product.now_price);
          }
        } else {
          this.priceWeituo = Number(this.priceWeituo) + Number(this.unit);
        }
      }
    },
    onSub() {
      if (this.trustIndex == 0) {
        this.price -= this.unit;
        this.price = Math.max(this.price, Number(this.product.now_price));
      } else {
        this.priceWeituo -= this.unit;
        this.priceWeituo = Math.max(this.priceWeituo, 0);
      }
    },
    changeDate(n) {
      let haoscend = n % 1000; //毫秒
      let scend = parseInt((n / 1000) % 60); //秒
      let minute = parseInt((n / 1000 / 60) % 60); //  分钟
      let hour = parseInt((n / 1000 / 60 / 60) % 24); //小时
      let day = Math.floor(n / 1000 / (24 * 3600)); //天数
      let txt = day > 0 ? `${day}天` : "";
      txt += hour > 0 ? `${hour < 10 ? "0" + hour : hour}小时` : "";
      txt += minute > 0 ? `${minute < 10 ? "0" + minute : minute}分` : "";
      txt += `${scend < 10 ? "0" + scend : scend}秒`;
      return txt;
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-head-info {
  width: 100%;
  height: 1.3rem;
  display: flex;
  & > div {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 0.28rem 0.12rem 0 0;
    label {
      font-size: 0.22rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: block;
      margin-bottom: 0.15rem;
      box-sizing: border-box;
      padding-left: 0.12rem;
    }
  }
  .goods-head-box1 {
    background: #ec4e4b;
    text-align: left;
    div {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.39rem;
      text-align: right;
    }
  }
  .goods-head-box2 {
    background: #64ada4;
    text-align: right;
    div {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.39rem;
      color: #f6ff09;
    }
  }
}
.cart-surper-mask {
  z-index: 888;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}
@keyframes fadeInDown {
  0% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
}
.cart-surper-box {
  position: absolute;
  width: 100%;
  height: 5.86rem;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -1px 1px 0px rgba(0, 24, 21, 0.15);
  z-index: 889;
  box-sizing: border-box;
  padding: 1.19rem 0.3rem 0;
  animation: fadeInDown 0.3s;
  & > i {
    width: 0.48rem;
    height: 0.48rem;
    background: url(../../assets/images/popup_btn_close2.png) no-repeat;
    background-size: 100% auto;
    cursor: pointer;
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
  }
  .cart-num-fns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      display: flex;
      align-items: center;
      i {
        width: 0.44rem;
        height: 0.44rem;
        cursor: pointer;
        background-size: 100% auto;
        display: inline-block;
        background-repeat: no-repeat;
      }
      .cart-surper-sub {
        background-image: url(../../assets/images/btn_jianhao.png);
      }
      .cart-surper-add {
        background-image: url(../../assets/images/btn_jiahao.png);
      }
      .cart-surper-num {
        font-size: 0.32rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.39rem;
        margin: 0 0.45rem;
      }
    }
  }
  .cart-surper-info {
    display: flex;
    margin-bottom: 0.4rem;
    img {
      width: 1.7rem;
      height: 1.7rem;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 0.08rem;
      margin-right: 0.3rem;
      object-fit: cover;
    }
    div {
      flex: 1;
      h5 {
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.39rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        margin-top: 0.3rem;
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.39rem;
        span {
          color: rgba(255, 16, 16, 1);
        }
      }
    }
  }
  .cart-surper-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.68rem;
    div {
      cursor: pointer;
      width: 3.34rem;
      height: 0.88rem;
      border: 0.02rem solid #64ada4;
      border-radius: 0.44rem;
      font-size: 0.32rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #64ada4;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > div:nth-of-type(2) {
      background: #64ada4;
      color: #ffffff;
    }
  }
}
.cart-btn {
  width: 1.33rem;
  height: 1.33rem;
  background: url(../../assets/images/right_btn_shopping.png) no-repeat;
  background-size: 100% auto;
  position: absolute;
  right: 0.2rem;
  bottom: 2.02rem;
  z-index: 99;
  cursor: pointer;
  & > div {
    width: 0.27rem;
    height: 0.27rem;
    position: absolute;
    background: #ec4e4b;
    border-radius: 50%;
    font-size: 0.16rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0.32rem;
    top: 0.18rem;
  }
}
.like-text {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 0.12rem;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  top: -5px;
}
.weituo-tag {
  font-size: 0.2rem;
}
.offer-list {
  padding: 0 0.3rem;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0.44rem;
  li {
    display: flex;
    align-items: center;
    &.active {
      i {
        opacity: 1;
      }
      h6 {
        color: #ff1010;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 0.32rem;
    }
    i {
      opacity: 0;
      width: 1.16rem;
      height: 0.4rem;
      background: url(../../assets/images/icon_champion.png) no-repeat;
      background-size: 100% auto;
      box-sizing: border-box;
      margin-right: auto;
    }
    h6 {
      font-size: 0.32rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    & > div {
      display: flex;
      align-items: center;
      margin-right: auto;
      img {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 0.14rem;
      }
      div {
        h5 {
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 0.28rem;
        }
        p {
          font-size: 0.22rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 0.24rem;
          margin-top: 0.1rem;
        }
      }
    }
  }
}
.goods-ps {
  position: relative;
  box-sizing: border-box;
  padding: 0 0.43rem 0.32rem 0.3rem;
  margin-bottom: 0.44rem;
  &::after {
    content: "";
    height: 0.22rem;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: #eff0ef;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.45rem;
    label {
      display: flex;
      align-items: center;
      width: 1.54rem;
      height: 0.58rem;
      box-sizing: border-box;
      margin-right: 0.23rem;
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      background: url(../../assets/images/icon_bg_text.png) no-repeat;
      background-size: 100% auto;
      padding-left: 0.18rem;
    }
    p {
      flex: 1;
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #64ada4;
      line-height: 0.4rem;
    }
  }
}
.goods-box-a {
  width: 100%;
  margin-bottom: 0.46rem;
  box-sizing: border-box;
  padding: 0 0.3rem;
  line-height: 0.39rem;
  p {
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 0.32rem;
    &:not(:last-of-type) {
      margin-bottom: 0.2rem;
    }
  }
  img {
    width: 100%;
    margin-top: 0.2rem;
  }
}
.goods-box {
  padding: 0.44rem 0.3rem;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  & > h5 {
    width: 100%;
    box-sizing: border-box;
    height: 0.72rem;
    padding: 0 0.29rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #007867;
    line-height: 0.48rem;
    background: #e0efed;
    margin-bottom: 0.44rem;
    &.new-title {
      width: 4.29rem;
      height: 0.85rem;
      background: url(../../assets/images/img_caidai.png) no-repeat;
      background-size: 100% auto;
      margin: 0 auto;
      justify-content: center;
      margin-bottom: 0.48rem;
      font-size: 0.32rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #007867;
      box-sizing: border-box;
      padding-top: 0.18rem;
    }
    a {
      font-size: 0.26rem;
      color: #007867 !important;
      display: flex;
      align-items: center;
      i {
        width: 0.12rem;
        height: 0.21rem;
        background: url(../../assets/images/top_btn_into.png) no-repeat;
        background-size: 100% auto;
        display: inline-block;
        margin-left: 0.14rem;
      }
    }
  }
}
.goods-browse {
  box-sizing: border-box;
  padding-bottom: 0.16rem;
  padding-top: 0.47rem;
  h5 {
    margin-bottom: 0.44rem;
    box-sizing: border-box;
    display: flex;
    padding: 0 0.3rem;
    align-items: center;
    &.new-title {
      width: 4.29rem;
      height: 0.85rem;
      background: url(../../assets/images/img_caidai.png) no-repeat;
      background-size: 100% auto;
      margin: 0 auto;
      justify-content: center;
      margin-bottom: 0.48rem;
      font-size: 0.32rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #007867;
      box-sizing: border-box;
      padding-top: 0.18rem;
    }
    img {
      width: 0.26rem;
      height: 0.16rem;
      margin-right: 0.14rem;
      vertical-align: middle;
    }
    p {
      box-sizing: border-box;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 0.4rem;
      span {
        box-sizing: border-box;
        margin: 0 0.04rem;
        font-size: 0.24rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.4rem;
      }
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0 1.05rem 0 0.85rem;
    li {
      width: (100%/6);
      text-align: center;
      margin-bottom: 0.28rem;
      img {
        width: 0.6rem;
        height: 0.6rem;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}
.goods-price {
  box-sizing: border-box;
  padding: 0 0.3rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  label {
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 0.4rem;
    margin-right: 0.19rem;
  }
  span {
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 0.4rem;
    &.red {
      font-size: 0.42rem;
      font-weight: bold;
      color: #ff1010;
      margin-right: 0.12rem;
    }
  }
}
.goods-info {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 0.33rem 0.1rem 0.2rem 0.3rem;
  background-color: #fff;
  border-top: 0.01rem#EFF0EF solid;
  border-bottom: 0.01rem#EFF0EF solid;
  ul {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    li {
      // min-width: 48%;
      // white-space: nowrap;
      width: 33%;
      &:nth-of-type(1),
      &:nth-of-type(4) {
        width: 25%;
      }
      &:nth-of-type(2),
      &:nth-of-type(5) {
        width: 35%;
      }
      &:nth-of-type(3),
      &:nth-of-type(6) {
        width: 40%;
      }
      margin-bottom: 0.17rem;
      label {
        font-size: 0.22rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #007867;
        line-height: 0.48rem;
        width: 1.1rem;
        margin-right: 0.03rem;
        display: block;
        // text-align: right;
      }
      span {
        font-size: 0.22rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.48rem;
        display: block;
      }
    }
  }
}
.goods-title {
  position: relative;
  box-sizing: border-box;
  padding: 0.4rem 0.3rem;
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.4rem;
  color: #333333;
}
.page-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  right: 0;
  left: 0;
  background-color: #fff;
}
.trust {
  height: 41px;
  width: 100%;
  bottom: 60px;
  display: flex;
  position: absolute;
  z-index: 92;
  justify-content: space-between;
  &::before {
    content: "";
    background-color: #e1e1e1;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(0.5);
    z-index: 101;
  }
  button {
    flex: 1;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #64ada4;
    background: #ffffff;
    &.active {
      color: #ffffff;
      background: #64ada4;
    }
    &:focus {
      outline: 0;
    }
  }
}
.foot {
  height: 60px;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: 9;
  justify-content: space-between;
  background: #ffffff;
  h5 {
    position: relative;
    box-sizing: border-box;
    padding-left: 0.57rem;
    display: flex;
    align-items: center;
    flex: 1;
    label {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-right: 0.23rem;
    }
    span {
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ff1010;
      line-height: 24px;
    }
    .kucun-text {
      font-size: 0.22rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 0.48rem;
      margin-left: 0.26rem;
    }
  }
  .fns {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-right: 0.12rem;
    flex: 1;
    h6.end {
      display: flex;
      align-items: center;
      margin-right: 0.11rem;
      box-sizing: border-box;
      padding-right: 0.33rem;
      height: 100%;
      padding-left: 0.32rem;
      i {
        width: 17px;
        height: 18px;
        background-image: url("../../assets/images/icon_clock_black.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin-right: 0.1rem;
      }
      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 15px;
      }
      &.active {
        background: #64ada4;
        i {
          background-image: url("../../assets/images/icon_clock_white.png");
        }
        p {
          color: #ffffff;
        }
      }
    }
    div {
      display: flex;
      align-items: center;
      flex: 1;
      p {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 48px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
      i {
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 100% auto;
        &.sub {
          background-image: url("../../assets/images/tab_btn_reduce.png");
        }
        &.add {
          background-image: url("../../assets/images/tab_btn_plus.png");
        }
      }
    }
  }
  &::before {
    content: "";
    background-color: #e1e1e1;
    height: 1px;
    position: absolute;
    left: 0;
    width: (7.5rem-3.18rem);
    top: 0;
    transform: translateY(0.5);
    z-index: 101;
  }
  button {
    width: 3.18rem;
    height: 100%;
    background: #ec4e4b;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fefefe;
    position: relative;
    img {
      width: 35px;
      margin-right: 10px;
    }
    &:focus {
      outline: 0;
    }
    &.end-btn {
      background-color: rgb(151, 147, 147);
    }
  }
}
.goods-status {
  height: 0.66rem;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.3rem;
  p {
    margin-right: 0.23rem;
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  span {
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 0.1rem;
    &.yellow {
      color: #f6ff09;
    }
  }
  &.goods-status-a {
    background: #c60808;
    img {
      width: 0.36rem;
      height: 0.36rem;
      margin-right: 0.13rem;
      vertical-align: middle;
    }
  }
  &.goods-status-b {
    background-color: #60aca1;
  }
}
.like {
  width: 20px;
  height: auto;
  cursor: pointer;
}
.swiper-slide {
  width: 100%;
  height: 7.66rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.banner-box {
  position: relative;
  background-color: #000;
  .banner-bar {
    position: absolute;
    right: 0.3rem;
    bottom: 0.38rem;
    height: 0.32rem;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0.16rem;
    z-index: 90;
    font-size: 0.22rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fefefe;
    box-sizing: border-box;
    padding: 0 0.19rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
